import { Fragment, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import cc from "classcat"
import kebabCase from "lodash.kebabcase"
import { Popover, Transition } from "@headlessui/react"
import { GatsbyImage } from "gatsby-plugin-image"
import {
  ChevronDownIcon,
  Bars3Icon,
  UserGroupIcon,
  AcademicCapIcon,
  Square3Stack3DIcon,
  BookOpenIcon,
  UsersIcon,
} from "@heroicons/react/24/solid"
import { LocaleContext } from "contexts"
import { OutboundLink } from "components/atoms/OutboundLink"
import LocalizedLink from "components/LocalizedLink"
import LogoHeader from "components/atoms/common/LogoHeader"
import { siteCountry } from "utils/env"
import useTranslations from "hooks/useTranslations"
import { MobilePopover } from "components/organisms/common/MenuMobile"
import {
  WideMenuWrapper,
  LocalesSwitch,
  MenuButtons,
  MoreLinksSection,
  SectionSubtitle,
  LinkListItem,
} from "../../molecules/common/Menu"
const PopoverButton = ({
  opened = false,
  title,
  className = "inline-flex",
  white,
}) => (
  <Popover.Button
    className={cc([
      "group items-center rounded-full py-1 pl-3 pr-2 text-sm font-bold focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:text-base",
      {
        "text-zinc-100 hover:text-zinc-300": white,
        "text-zinc-700 hover:text-zinc-900 ": !white,
      },
      className,
    ])}
  >
    <span>{title}</span>
    <ChevronDownIcon
      className={cc([
        "ml-1 h-5 w-5 group-hover:text-zinc-500",
        {
          "rotate-180 transform": opened,
          "text-zinc-100": white,
          "text-zinc-600": !white,
        },
      ])}
      aria-hidden="true"
    />
  </Popover.Button>
)

const NarrowMenuWrapper = ({ children }) => (
  <div className="mx-auto max-w-md rounded-lg">{children}</div>
)

const BlogPostCard = ({ post }) => {
  const slug = `/blog/${post?.slug?.text || kebabCase(post.title.text)}/`
  return (
    <Popover.Button
      as={LocalizedLink}
      key={slug}
      to={slug}
      className="-m-3 flex rounded-lg p-3 hover:bg-zinc-100"
    >
      <div className="hidden shrink-0 sm:block">
        <GatsbyImage
          image={post.image_main.gatsbyImageData}
          className="h-20 w-32 rounded-md object-cover"
        />
      </div>
      <div className="w-0 flex-1 sm:ml-8">
        <h4 className="mb-0 text-base font-medium text-zinc-900">
          {post.title.text}
        </h4>
        <p className="mb-0 mt-1 text-sm text-zinc-800">{post.spoiler?.text}</p>
      </div>
    </Popover.Button>
  )
}

const RecentBlogPostsSection = ({ blogPosts }) => {
  const { navMenuBlog, navMenuBlogAll } = useTranslations()

  return (
    <div className="rounded-xl bg-kkm-darkgreen px-4 py-7 shadow-lg sm:px-6 lg:px-8 xl:pl-12">
      <div className="space-y-6">
        <SectionSubtitle text={navMenuBlog} />
        <ul className="space-y-6">
          {blogPosts.map((post) => (
            <BlogPostCard post={post.data} key={post.id} />
          ))}
        </ul>
      </div>
      <div className="mt-6 text-sm font-medium">
        <Popover.Button
          as={LocalizedLink}
          to="/blog"
          className="text-teal-800 hover:text-black"
        >
          {navMenuBlogAll} <span aria-hidden="true">&rarr;</span>
        </Popover.Button>
      </div>
    </div>
  )
}

const TwoSectionsPanel = ({ blogPosts }) => {
  return (
    <div className="relative">
      <div className="absolute inset-0 flex">
        <div className="w-1/2" />
        <div className="w-1/2" />
      </div>
      <WideMenuWrapper>
        <div className="relative grid grid-cols-1 gap-4 lg:grid-cols-2">
          <div className="flex flex-col rounded-xl bg-zinc-700 shadow-lg">
            <MoreLinksSection />
          </div>
          <RecentBlogPostsSection blogPosts={blogPosts} />
        </div>
      </WideMenuWrapper>
    </div>
  )
}

const DropdownLink = ({ name, Icon }) => {
  return (
    <div
      key={name}
      className="-m-2 flex items-center rounded-lg p-2 transition duration-150 ease-in-out hover:bg-zinc-600 focus:outline-none focus-visible:ring focus-visible:ring-teal-500 focus-visible:ring-opacity-50"
    >
      <Icon
        aria-hidden="true"
        className="flex h-6 w-6 shrink-0 items-center justify-center text-white"
      />
      <div className="ml-3">
        <p className="m-0 whitespace-nowrap text-base font-bold text-white">
          {name}
        </p>
      </div>
    </div>
  )
}

const LinkList = ({ list }) => {
  return (
    <div className="relative grid gap-4 bg-zinc-700 p-4 pb-2">
      {list.map((item) => (
        <LinkListItem {...item} Component={DropdownLink} />
      ))}
    </div>
  )
}

const PopoverMenu = ({ title, children, className, white }) => {
  return (
    <Popover>
      {({ open }) => (
        <>
          <PopoverButton
            title={title}
            className={cc(["inline-flex"], className)}
            white={white}
            opened={open}
          />
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 -translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-1"
          >
            <Popover.Panel className="absolute inset-x-3 top-full z-20">
              {children}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

const DropdownMenu = ({ title, children, className, white }) => {
  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <PopoverButton
            title={title}
            opened={open}
            className={cc(["inline-flex"], className)}
            white={white}
          />
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute -left-1 z-20 mt-4 max-w-sm transform sm:px-0 lg:max-w-3xl">
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <NarrowMenuWrapper>{children}</NarrowMenuWrapper>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

const MenuLink = ({
  title,
  to,
  className = "inline-flex font-bold",
  white,
  outbound = false,
}) => {
  return outbound ? (
    <OutboundLink
      href={to}
      className={cc([
        "group items-center rounded-full px-3 py-1 text-sm leading-5 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:text-base",
        {
          "text-zinc-100 hover:text-zinc-300": white,
          "text-zinc-700 hover:text-zinc-900 ": !white,
        },
        className,
      ])}
    >
      {title}
    </OutboundLink>
  ) : (
    <LocalizedLink
      to={to}
      className={cc([
        "group items-center rounded-full px-3 py-1 text-sm leading-5 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:ring-offset-2 sm:text-base",
        {
          "text-zinc-100 hover:text-zinc-300": white,
          "text-zinc-700 hover:text-zinc-900 ": !white,
        },
        className,
      ])}
    >
      {title}
    </LocalizedLink>
  )
}

export const MobileMenuButton = ({ white }) => {
  return (
    <Popover.Button
      className={cc([
        "inline-flex items-center justify-center rounded-md  border p-2 hover:bg-zinc-100 hover:text-zinc-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500",
        {
          "text-white": white,
          "text-zinc-600": !white,
        },
      ])}
    >
      <span className="sr-only">Open menu</span>
      <Bars3Icon className="h-6 w-6" aria-hidden="true" />
    </Popover.Button>
  )
}

export default function MenuFullWidth({ white, Logo, logoLink }) {
  const { locale } = useContext(LocaleContext)
  const {
    navMenuSchools,
    navMenuCompanies,
    navMenuOther,
    menuLinkSchoolMain,
    menuLinkSchoolTrainings,
    menuLinkSchoolLessons,
    menuLinkCompaniesMain,
    menuLinkCompaniesTrainings,
    menuLinkCompaniesOnboarding,
    menuLinkSchoolsOnboarding,
    navMenuOtherCompany,
    menuLinkPublications,
  } = useTranslations()
  const blogPosts = useStaticQuery(graphql`
    query BlogPostsQuery {
      allPrismicBlogPost(sort: { data: { publication_date: DESC } }) {
        nodes {
          id
          lang
          data {
            image_main {
              gatsbyImageData(width: 144)
            }
            publication_date
            show_in_menu
            spoiler {
              text
            }
            tags
            title {
              text
            }
            slug {
              text
            }
          }
        }
      }
    }
  `)

  const lastBlogPostForLocale = blogPosts.allPrismicBlogPost.nodes
    .filter(({ lang }) => lang === locale)
    .slice(0, 1)

  const schoolLinks = [
    {
      name: menuLinkSchoolMain,
      href: "/schools",
      Icon: UserGroupIcon,
      hide: siteCountry == "global",
    },
    {
      name: menuLinkSchoolTrainings,
      href: "/schools/trainings",
      Icon: AcademicCapIcon,
      hide: siteCountry == "global",
    },
    {
      name: menuLinkSchoolLessons,
      href: "/lessons/schools",
      Icon: Square3Stack3DIcon,
      hide: siteCountry == "global",
    },
    {
      name: menuLinkSchoolsOnboarding,
      href: "/schools/onboarding",
      Icon: UsersIcon,
      hide: siteCountry == "global",
    },
    {
      name: menuLinkPublications,
      href: "/schools/annual-reports",
      Icon: BookOpenIcon,
      hide: siteCountry !== "se",
    },
  ]

  const companyLink = {
    name: menuLinkCompaniesMain,
    href: "/pro",
    Icon: UserGroupIcon,
  }

  const companyTrainingLink = {
    name: menuLinkCompaniesTrainings,
    href: "/pro/trainings",
    Icon: AcademicCapIcon,
    hide: locale === "en-fi",
  }

  const companyOnboardingLink = {
    name: menuLinkCompaniesOnboarding,
    href: "/pro/onboarding",
    Icon: BookOpenIcon,
  }

  const companiesInboundLink = {
    title: navMenuCompanies,
    href: "/pro",
    type: "link",
    outbound: false,
  }

  const menuLinks = {
    fi: [
      {
        title: navMenuCompanies,
        type: "dropdown",
        outbound: false,
        children: (
          <LinkList
            list={[
              companyLink,
              companyTrainingLink,
              companyOnboardingLink,
            ].filter((p) => !p.hide)}
          />
        ),
      },
      {
        title: navMenuSchools,
        type: "dropdown",
        outbound: false,
        children: <LinkList list={schoolLinks.filter((p) => !p.hide)} />,
      },
      {
        title: navMenuOther,
        type: "popover",
        outbound: false,
        children: <TwoSectionsPanel blogPosts={lastBlogPostForLocale} />,
      },
    ],
    se: [
      {
        title: navMenuOtherCompany,
        href: "/us",
        type: "link",
        outbound: false,
      },
      {
        title: navMenuSchools,
        type: "dropdown",
        outbound: false,
        children: <LinkList list={schoolLinks.filter((p) => !p.hide)} />,
      },
      {
        title: navMenuOther,
        type: "popover",
        outbound: false,
        children: <TwoSectionsPanel blogPosts={lastBlogPostForLocale} />,
      },
    ],
    global: [
      companiesInboundLink,
      {
        title: navMenuSchools,
        href: "/schools",
        type: "link",
        outbound: false,
      },
      {
        title: navMenuOther,
        type: "popover",
        outbound: false,
        children: <TwoSectionsPanel blogPosts={lastBlogPostForLocale} />,
      },
    ],
  }

  return (
    <Popover className="relative">
      <>
        <div className="pointer-events-none inset-0 z-30 " aria-hidden="true" />
        <div className="z-20">
          <div className="relative mx-auto py-2 md:space-x-4">
            <div className="flex items-center space-x-4">
              <div className="mx-auto flex max-w-7xl flex-1 space-x-4 px-4 sm:px-6 xl:px-8">
                <LogoHeader white={white} Logo={Logo} to={logoLink} />
                <div className="hidden pt-2 md:flex-1 md:items-center lg:flex">
                  <Popover.Group
                    as="nav"
                    className="align-center flex space-x-3"
                  >
                    <>
                      {menuLinks[siteCountry].map((link) => {
                        // when no dropdown needed
                        if (link.type === "link") {
                          return (
                            <MenuLink
                              title={link.title}
                              to={link.href}
                              white={white}
                              outbound={link.outbound}
                              key={link.title}
                            />
                          )
                        }
                        // for centered wide menus
                        if (link.type === "popover") {
                          return (
                            <PopoverMenu
                              title={link.title}
                              white={white}
                              key={link.title}
                            >
                              {link.children}
                            </PopoverMenu>
                          )
                        }
                        // for list of links below button
                        if (link.type === "dropdown") {
                          return (
                            <DropdownMenu
                              title={link.title}
                              white={white}
                              key={link.title}
                            >
                              {link.children}
                            </DropdownMenu>
                          )
                        }
                        return null
                      })}
                    </>
                  </Popover.Group>
                </div>
              </div>
            </div>
            <div className="align-center absolute bottom-0 right-0 top-0 flex items-center pr-4 lg:hidden">
              <MobileMenuButton white={white} />
            </div>
            <div className="align-center absolute bottom-0 right-0 top-2 hidden flex-row items-center space-x-4 pr-4 md:ml-12 lg:flex">
              <LocalesSwitch white={white} />
              <div className="items-center md:ml-12">
                <MenuButtons />
              </div>
            </div>
          </div>
        </div>
      </>
      <MobilePopover list={[...schoolLinks]} />
    </Popover>
  )
}
