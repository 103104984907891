import { Fragment, useContext } from "react"
import { Popover, Transition } from "@headlessui/react"
import useTranslations from "hooks/useTranslations"
import { LocaleContext } from "contexts"
import cc from "classcat"
import {
  XMarkIcon,
  UserGroupIcon,
  AcademicCapIcon,
  BriefcaseIcon,
  BookOpenIcon,
} from "@heroicons/react/24/solid"
import {
  WideMenuWrapper,
  LocalesSwitch,
  MenuButtons,
  MoreLinksSection,
} from "../../molecules/common/Menu"
import { OutboundLink } from "components/atoms/OutboundLink"
import LocalizedLink from "components/LocalizedLink"
import { siteCountry } from "utils/env"

const ProductIcon = ({ Icon }) => {
  return (
    <div className=" shrink-0">
      <span className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-kkm-green text-white xl:h-12 xl:w-12">
        <Icon className="h-4 w-4 xl:h-8 xl:w-8" aria-hidden="true" />
      </span>
    </div>
  )
}

const ProdcutCardLink = ({ href, outbound, children, className }) =>
  outbound ? (
    <OutboundLink className={className} href={href}>
      {children}
    </OutboundLink>
  ) : (
    <Popover.Button as={LocalizedLink} className={className} to={href}>
      {children}
    </Popover.Button>
  )

const ProductCard = ({ name, href, description, Icon, outbound }) => {
  const { navMenuProductCardMore } = useTranslations()
  return (
    <div className="flex xl:shadow-lg">
      <ProdcutCardLink
        key={name}
        href={href}
        outbound={outbound}
        className="-mx-2 flex flex-1 flex-col justify-center rounded-full px-2 py-2 text-kkm-green hover:bg-kkm-green hover:text-white xl:m-0 xl:rounded-lg xl:bg-kkm-darkgreen xl:p-4"
      >
        <div className="flex items-center lg:h-full lg:flex-col xl:items-start">
          <ProductIcon Icon={Icon} />
          <div className="ml-4 flex items-center lg:flex-1 lg:flex-col lg:items-start lg:justify-between xl:ml-0 xl:mt-4">
            <div className="">
              <p className="mb-0 text-sm font-bold text-zinc-800 xl:text-base">
                {name}
              </p>
              <p className="mb-0 mt-1 hidden text-sm text-zinc-800 xl:block">
                {description}
              </p>
            </div>
            <p className="mb-0 mt-2 hidden text-sm font-bold text-teal-100 xl:mt-4 xl:block">
              {navMenuProductCardMore} <span aria-hidden="true">&rarr;</span>
            </p>
          </div>
        </div>
      </ProdcutCardLink>
    </div>
  )
}

const ProductsSection = ({ list }) => {
  return (
    <WideMenuWrapper>
      <div
        className={cc([
          " grid gap-x-8 gap-y-1 md:grid-cols-2 xl:gap-4 xl:py-0",
          { "xl:grid-cols-4": list.length === 4 },
          { "xl:grid-cols-3": list.length === 3 },
          { "xl:grid-cols-2": list.length === 2 },
        ])}
      >
        {list
          .filter((p) => !p.hide)
          .map((item) => (
            <ProductCard {...item} />
          ))}
      </div>
    </WideMenuWrapper>
  )
}
export const MobilePopover = ({ open, list }) => {
  const {
    navMenuCompanies,
    navMenuSchools,
    menuLinkCompaniesMainFull,
    menuLinkCompaniesTrainingsFull,
    menuLinkCompaniesOnboarding,
    menuLinkSchoolsOnboarding,
  } = useTranslations()
  const { locale } = useContext(LocaleContext)

  const companyLink = {
    name: menuLinkCompaniesMainFull,
    href: "/pro",
    Icon: UserGroupIcon,
  }

  const companyTrainingLink = {
    name: menuLinkCompaniesTrainingsFull,
    href: "/pro/trainings",
    Icon: AcademicCapIcon,
    hide: locale === "en-fi",
  }

  const companyOnboardingLink = {
    name: menuLinkCompaniesOnboarding,
    href: "/pro/onboarding",
    Icon: BookOpenIcon,
  }

  const schoolOnboardingLink = {
    name: menuLinkSchoolsOnboarding,
    href: "/schools/onboarding",
    Icon: BookOpenIcon,
  }

  const companiesInboundLink = {
    name: navMenuCompanies,
    href: "/pro",
    Icon: BriefcaseIcon,
    outbound: false,
  }
  const schoolsInboundLink = {
    name: navMenuSchools,
    href: "/schools",
    Icon: AcademicCapIcon,
    outbound: false,
  }

  const mobileLinks = {
    fi: [companyLink, companyTrainingLink, companyOnboardingLink, ...list],
    se: list.filter((item) => item.href !== "/schools/annual-reports"),
    global: [companiesInboundLink, schoolsInboundLink],
  }

  return (
    <Transition
      show={open}
      as={Fragment}
      enter="duration-200 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="duration-100 ease-in"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <Popover.Panel
        focus
        static
        className="absolute inset-x-0 top-0 z-30 origin-top-right transform p-2 transition sm:left-1/4 lg:hidden xl:left-1/2"
      >
        <div className="divide-y-2 divide-zinc-50 rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5">
          <div className="p-4">
            <div className="flex items-center justify-between ">
              <div className="align-start flex flex-row items-center space-x-4">
                <MenuButtons />
                <LocalesSwitch linkStyle="mobile" />
              </div>

              <div className="-mr-2 -mt-7">
                <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-zinc-400 hover:bg-zinc-100 hover:text-zinc-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-teal-500">
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
            </div>
            <nav className="mt-4 lg:hidden xl:mt-8">
              <ProductsSection list={mobileLinks[siteCountry]} />
            </nav>
          </div>
          <div className="rounded-lg bg-zinc-700 px-5 lg:hidden">
            <MoreLinksSection />
          </div>
        </div>
      </Popover.Panel>
    </Transition>
  )
}
