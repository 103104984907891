import useTranslations from "hooks/useTranslations"
import { LocaleContext } from "contexts"
import { useContext } from "react"
import { Popover, Transition } from "@headlessui/react"
import Button from "components/buttons/Button"
import { MenuDropdown } from "../../organisms/common/MenuDropdown"
import {
  ChevronDownIcon,
  XMarkIcon,
  Bars3Icon,
  UserGroupIcon,
  AcademicCapIcon,
  CakeIcon,
  SignalIcon,
  BriefcaseIcon,
  NewspaperIcon,
  InformationCircleIcon,
  GlobeEuropeAfricaIcon,
  AdjustmentsVerticalIcon,
  Square3Stack3DIcon,
  BookOpenIcon,
  PhoneIcon,
  PlayCircleIcon,
  UsersIcon,
  BuildingLibraryIcon,
} from "@heroicons/react/24/solid"
import { siteCountry } from "utils/env"
import countries from "../../../../config/i18n"
import { OutboundLink } from "components/atoms/OutboundLink"
import LocalizedLink from "components/LocalizedLink"
import Cookies from "js-cookie"
import { COOKIE_NOTICE_ACCEPTED_NAME } from "utils/constants"

const callsToAction = [
  { name: "Watch demo", href: "#", icon: PlayCircleIcon },
  { name: "Contact sales", href: "#", icon: PhoneIcon },
]

export const LocalesSwitch = ({ className = "", linkStyle, white = false }) => {
  const { locale } = useContext(LocaleContext)
  const { [locale]: currentLocale, ...otherCountryLocales } =
    countries[siteCountry]
  const locales = Object.values(otherCountryLocales).map((loc) => ({
    name: loc.langName,
    key: loc.langName,
    path: loc.path,
    isDefault: loc.default,
  }))

  const currentPath =
    typeof window !== "undefined"
      ? window.location.pathname.replace(/\/\w\w-\w\w/, "")
      : "/"

  if (locales.length > 0) {
    return (
      <MenuDropdown
        items={locales}
        footer={callsToAction}
        title={currentLocale.langName}
        currentPath={currentPath}
        linkStyle={linkStyle}
        white={white}
      />
    )
  }
}

export const MenuButtons = () => {
  const { buttonLogin, appLink } = useTranslations()

  return (
    <Button bgStyle="primary" label={buttonLogin} link={appLink} outbound />
  )
}

export const WideMenuWrapper = ({ children }) => (
  <div className="mx-auto max-w-screen-xl">{children}</div>
)

export const SectionSubtitle = ({ text }) => {
  return (
    <h3 className="mb-0 text-[0.7em] font-medium uppercase text-zinc-200">
      {text}
    </h3>
  )
}

export const LinkListItem = ({
  name,
  href,
  Icon,
  outbound,
  onClick,
  Component,
}) => {
  return (
    <Popover.Button as="li" className="flow-root" key={name}>
      {outbound && href && (
        <OutboundLink href={href}>
          <Component name={name} Icon={Icon} />
        </OutboundLink>
      )}
      {onClick && <Component name={name} Icon={Icon} onClick={onClick} />}
      {!outbound && href && (
        <Popover.Button as={LocalizedLink} to={href}>
          <Component name={name} Icon={Icon} />
        </Popover.Button>
      )}
    </Popover.Button>
  )
}

const LinkListItemInner = ({ name, Icon, onClick }) => {
  const onKeyUp = (e) => {
    if (e.key && e.key === "Enter") {
      onClick(e)
    }
  }
  return (
    <div
      className="-m-3 flex items-start rounded-full px-3 py-2 text-sm font-medium text-zinc-100 hover:bg-zinc-50 hover:text-zinc-700"
      onClick={onClick}
      role="button"
      tabIndex="0"
      onKeyUp={onKeyUp}
    >
      <Icon
        className="mt-0.5 h-4 w-4 shrink-0 text-zinc-400"
        aria-hidden="true"
      />
      <span className="ml-2">{name}</span>
    </div>
  )
}

const LinksList = ({ title, list, className }) => {
  return (
    <div className="space-y-5">
      {title ? <SectionSubtitle text={title} /> : null}
      <ul className={className ?? "space-y-4"}>
        {list.map((item) => (
          <LinkListItem {...item} Component={LinkListItemInner} />
        ))}
      </ul>
    </div>
  )
}

const getOtherCountriesLinks = () => {
  const { [siteCountry]: remove, ...otherCountries } = countries
  return Object.values(otherCountries).map((c) => {
    const { country, url } = Object.values(c)[0]
    return {
      name: country,
      href: url,
      Icon: GlobeEuropeAfricaIcon,
      outbound: true,
    }
  })
}

export const MoreLinksSection = () => {
  const {
    navMenuCompanies,
    navMenuOtherCompany,
    navMenuOtherResources,
    navMenuDataProtection,
    menuLinkAboutUs,
    menuLinkMedia,
    menuLinkLegal,
    menuLinkBlog,
    menuLinkPublications,
    menuLinkCourses,
    menuLinkHelpdesk,
    helpdeskLink,
    menuLinkCountry,
    menuLinkCookiePrefs,
  } = useTranslations()

  const { locale } = useContext(LocaleContext)

  const company = [
    { name: menuLinkAboutUs, href: "/us", Icon: CakeIcon },
    { name: menuLinkMedia, href: "/media", Icon: SignalIcon },
  ]

  const legal = {
    name: menuLinkLegal,
    href: "/legal",
    Icon: BuildingLibraryIcon,
  }

  const blog = { name: menuLinkBlog, href: "/blog", Icon: NewspaperIcon }
  const publications = {
    name: menuLinkPublications,
    href: "/schools/annual-reports",
    Icon: BookOpenIcon,
  }

  const helpdesk = {
    name: menuLinkHelpdesk,
    href: helpdeskLink,
    Icon: InformationCircleIcon,
    outbound: true,
  }

  const cookieSettings = {
    name: menuLinkCookiePrefs,
    Icon: AdjustmentsVerticalIcon,
    onClick: () => {
      Cookies.set(COOKIE_NOTICE_ACCEPTED_NAME, "false", {
        expires: 365,
      })
      window.location.reload()
    },
  }

  const companyLinksByCountry = {
    global: {
      "en-us": [
        { name: menuLinkMedia, href: "/media", Icon: SignalIcon },
        { name: menuLinkLegal, href: "/legal", Icon: BriefcaseIcon },
      ],
      "fi-fi": [
        { name: menuLinkMedia, href: "/media", Icon: SignalIcon },
        { name: menuLinkLegal, href: "/legal", Icon: BriefcaseIcon },
      ],
    },
    fi: {
      "fi-fi": company,
      "sv-fi": company,
      "en-fi": company,
    },
    se: {
      "sv-se": [
        { name: menuLinkAboutUs, href: "/us", Icon: CakeIcon },
        { name: menuLinkMedia, href: "/media", Icon: SignalIcon },
      ],
      "en-us": [
        // { name: menuLinkAboutUs, href: "/us", Icon: CakeIcon },
        { name: menuLinkMedia, href: "/media", Icon: SignalIcon },
      ],
    },
  }

  const resourcesLinksByCountry = {
    global: {
      "en-us": [blog],
      "fi-fi": [
        {
          name: menuLinkCourses,
          href: "/pro/trainings",
          Icon: AcademicCapIcon,
        },
        blog,
        publications,
      ],
    },
    fi: {
      "fi-fi": [blog, publications, helpdesk],
      "sv-fi": [blog, publications, helpdesk],
      "en-fi": [blog, publications, helpdesk],
    },
    se: {
      "sv-se": [blog, publications],
      "en-us": [blog],
    },
  }

  const dataProtectionLinksByCountry = {
    global: {
      "en-us": [legal, cookieSettings],
      "fi-fi": [legal, cookieSettings],
    },
    fi: {
      "fi-fi": [legal, cookieSettings],
      "sv-fi": [legal, cookieSettings],
      "en-fi": [legal, cookieSettings],
    },
    se: {
      "sv-se": [cookieSettings, legal],
      "en-us": [cookieSettings, legal],
    },
  }

  return (
    <nav className="flex grow flex-col divide-y divide-zinc-500 ">
      {siteCountry == "se" && (
        <div className="pb-5 pt-8 lg:px-6 xl:px-8">
          <LinksList
            list={[
              {
                name: navMenuCompanies,
                href: "/pro",
                Icon: BriefcaseIcon,
              },
            ]}
          />
        </div>
      )}
      <div className="grid grow grid-cols-1 gap-x-4 gap-y-4 py-7 sm:grid-cols-3 sm:gap-x-8 sm:gap-y-0 lg:px-6 xl:px-8">
        <LinksList
          title={navMenuOtherCompany}
          list={companyLinksByCountry[siteCountry][locale]}
        />
        <LinksList
          title={navMenuDataProtection}
          list={dataProtectionLinksByCountry[siteCountry][locale]}
        />
        <LinksList
          title={navMenuOtherResources}
          list={resourcesLinksByCountry[siteCountry][locale]}
        />
      </div>
      <div className="pb-5 pt-7 lg:px-6 xl:px-8">
        <LinksList
          title={`${menuLinkCountry} / Switch region`}
          list={getOtherCountriesLinks()}
          className="flex space-x-8 space-y-0 "
        />
      </div>
    </nav>
  )
}
